'use client';

import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import {
  useLikeArticleMutation,
  useUnlikeArticleMutation,
} from '@/RTK/services/articleApi';
import {
  useBookmarkArticleMutation,
  useGetUserQuery,
} from '@/RTK/services/userApi';
import { Bookmark, ImageIcon, MessageCircleMore, Share2 } from 'lucide-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { AiFillLike, AiOutlineLike } from 'react-icons/ai';
import { FiCopy } from 'react-icons/fi';
import { HiMiniBookmark } from 'react-icons/hi2';
import { useSelector } from 'react-redux';

import useLike from '@/hooks/useLike';

import { share } from '../../../../../public/images';
import { demoCoverImage } from '../../../../assets';
import { formatDate } from '../../../../utils/Formatdate';
import CommentModal from '../explore/CommentModal';
import ShareModal from '../view-article/components/ShareModal';

const ExploreArticleCard = ({ article }) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [copy, setCopy] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [imageError, setImageError] = useState(false);
  // const [bookmark, setBookmark] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const { data } = useGetUserQuery(undefined, {
    skip: !token,
  });
  const [likeArticle] = useLikeArticleMutation();
  const [unlikeArticle] = useUnlikeArticleMutation();
  const [bookmarkArticle, { isSuccess }] = useBookmarkArticleMutation();
  const currentUser = useSelector((state) => state.auth.user);
  const { likes, isLiked, handleLike } = useLike(
    article?.likes,
    article?._id,
    currentUser?._id
  );

  const bookmark = data?.user?.bookmarks?.includes(article?._id);
  const handleCopy = () => {
    const url = window.location.origin + `/view-article/${article?._id}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopy(true); // Update the copy state
        toast.success('!Copied');
        setTimeout(() => setCopy(false), 2000); // Reset copy state after 2 seconds
      })
      .catch((error) => {
        console.error('Error copying URL:', error);
      });
  };
  const handleBookmark = async () => {
    await bookmarkArticle(article?._id);
  };
  // useEffect(() => {
  //   if (isSuccess) {
  //     setBookmark(!bookmark);
  //   }
  // }, [isSuccess]);
  const truncateText = (text, limit) => {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return text;
  };

  // Add this function to generate placeholder background
  const generatePlaceholderBg = () => {
    const colors = [
      'bg-gradient-to-br from-purple-50 to-pink-50',
      'bg-gradient-to-br from-blue-50 to-purple-50',
      'bg-gradient-to-br from-indigo-50 to-cyan-50',
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div className="group relative bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden">
      <div className="flex md:flex-row flex-col-reverse gap-6 p-5">
        {/* Image Container */}
        <Link
          href={`/view-article/${article?._id}`}
          className="relative md:w-[280px] w-full h-[200px] md:h-[160px] cursor-pointer overflow-hidden rounded-xl group/image"
        >
          {article?.coverImage && !imageError ? (
            <Image
              src={article.coverImage}
              alt="article cover"
              className="object-cover transition-transform duration-300 group-hover:scale-105"
              fill
              sizes="(max-width: 768px) 100vw, 280px"
              onError={() => setImageError(true)}
            />
          ) : (
            <div className="w-full h-full bg-gradient-to-br from-purple-50 to-white relative">
              <div className="absolute inset-0 overflow-hidden">
                <div className="w-16 h-16 bg-purple-100/50 rounded-full absolute -top-6 -right-6" />
                <div className="w-20 h-20 bg-pink-100/30 rounded-full absolute -bottom-8 -left-8" />
              </div>
              <div className="relative flex flex-col items-center justify-center h-full">
                <div className="text-center transform -translate-y-2 select-none">
                  {/* <div className="w-12 h-12 mx-auto mb-2 rounded-xl bg-white/80 backdrop-blur-sm flex items-center justify-center">
                    <ImageIcon className="w-6 h-6 text-purple-300" />
                  </div> */}
                </div>
                {/* Title only on placeholder */}
                <div className="px-6 text-center">
                  <h3 className="text-lg font-medium text-gray-700 line-clamp-2">
                    {article?.title}
                  </h3>
                </div>
              </div>
            </div>
          )}
        </Link>

        {/* Content Container */}
        <div className="flex flex-col flex-1 gap-4 select-text">
          {/* Author Info */}
          <div className="flex items-center gap-3">
            <div
              onClick={() => router.push(`/@${article?.author?.username}`)}
              className="flex items-center gap-2.5 cursor-pointer group/author"
            >
              <div className="w-9 h-9 rounded-full overflow-hidden ring-2 ring-gray-100 group-hover/author:ring-purple-100 transition-all">
                {article?.author?.profileImage ? (
                  <Image
                    src={article?.author?.profileImage}
                    alt={article?.author?.name}
                    width={36}
                    height={36}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center">
                    <span className="text-sm font-medium text-purple-600">
                      {article?.author?.name?.charAt(0) || 'A'}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-800 group-hover/author:text-purple-600 transition-colors">
                  {article?.author?.name || 'Author'}
                </p>
                <span className="text-xs text-gray-500">
                  @{article?.author?.username}
                </span>
              </div>
            </div>
            <span className="text-sm text-gray-300">•</span>
            <span className="text-sm text-gray-500">
              {formatDate(article?.createdAt)}
            </span>
          </div>

          {/* Article Title & Subtitle */}
          <div className="space-y-2.5">
            <Link href={`/view-article/${article?._id}`}>
              <h2 className="text-xl font-semibold text-gray-900 leading-snug group-hover:text-purple-700 transition-colors cursor-pointer">
                {truncateText(article?.title, 12)}
              </h2>
            </Link>

            {/* <p className="text-gray-600 text-sm leading-relaxed">
              {truncateText(article?.subTitle, 20)}
            </p> */}
          </div>

          {/* Interaction Bar */}
          {
            <div className="flex items-center justify-between mt-auto pt-3 border-t border-gray-100">
              <div className="flex items-center gap-6">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="flex items-center gap-1.5 text-gray-500 hover:text-purple-600 transition-colors"
                >
                  <MessageCircleMore size={18} />
                  {article?.comments?.length > 0 && (
                    <span className="text-sm font-medium">
                      {article?.comments?.length}
                    </span>
                  )}
                </button>

                <button
                  onClick={handleLike}
                  className="flex items-center gap-1.5 text-gray-500 hover:text-purple-600 transition-colors"
                >
                  {isLiked ? (
                    <AiFillLike className="text-purple-600" size={18} />
                  ) : (
                    <AiOutlineLike size={18} />
                  )}
                  <span className="text-sm font-medium">{likes.length}</span>
                </button>

                <button
                  onClick={handleBookmark}
                  className="text-gray-500 hover:text-purple-600 transition-colors"
                >
                  {bookmark ? (
                    <HiMiniBookmark className="text-purple-600" size={18} />
                  ) : (
                    <Bookmark size={18} />
                  )}
                </button>

                {/* <button
                  onClick={handleCopy}
                  className={`text-gray-500 hover:text-purple-600 transition-colors ${copy ? 'text-purple-600' : ''}`}
                >
                  <FiCopy size={18} />
                </button> */}
                <button
                  onClick={() => setShareModal(true)}
                  className="text-gray-600 hover:text-gray-900 transition-colors"
                >
                  <Share2 className="w-4 h-4 text-gray-500 hover:text-purple-600 transition-colors" />
                </button>
              </div>
            </div>
          }
        </div>
      </div>

      {/* Hover overlay effect */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/[0.03] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" />
      {shareModal && (
        <ShareModal
          article={article}
          isOpen={shareModal}
          onClose={() => setShareModal(false)}
        />
      )}
      {/* Comment Modal */}
      {isOpen && (
        <CommentModal
          isOpen={isOpen}
          articleId={article?._id}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default ExploreArticleCard;
